import React from 'react';
import { LocaleEnum, getLocales, useI18n } from '../../utils/i18n';
import { PageContext } from '../../types/page';
import { LocalizationData } from '../../types/general';
import { AuthPageData, DashboardPageType } from '../../types/auth';
import { AuthPageBase } from '../../context/global-context';
import DashboardLayout from '../../components/global/dashboard/dashboardLayout/dashboardLayout';
import BusinessFormMain from '../../components/dashboard/business/form/businessFormMain';
import { formTypes } from '../../types/forms';

type DashboardBusinessTranslateContext = PageContext & {
  locale: LocaleEnum;
  slug: string;
};

const DashboardBusinessTranslate : React.FC<{
  id: number;
  pageContext: DashboardBusinessTranslateContext;
}> = ({ id, pageContext }) => {
  const { translations, locale, slug } = pageContext;

  const i18n = useI18n(translations);
  const locales = getLocales([locale]);
  const localizationsData: LocalizationData[] = [];

  locales.forEach((l) => {
    const slug = i18n.t('account.business.translate.slug', l) ||
      'account/business/translate';

      localizationsData.push({
        attributes: {
          slug: `${slug}/${id}`,
          locale: l
        }
      });    
  });

  const instance: AuthPageData = {
    dashboardPageType: DashboardPageType.translateBusiness,
    manageOpportunitiesPage: false,
    pageEntityId: id,
    attributes: {
      slug: `${slug}/${id}`,
      locale,
      localizations: {
        data: localizationsData
      }
    }
  };

  return (
    <AuthPageBase translations={translations} instance={instance}>
      <DashboardLayout>
        <BusinessFormMain type={formTypes.translate} />
      </DashboardLayout>
    </AuthPageBase>
  );
}

export default DashboardBusinessTranslate;